<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
// import moment from 'moment';
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Digitalisasi SPK",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Digitalisasi SPK",
      items: [
        {
          text: "Digitalisasi",
          href: "/",
        },
        {
          text: "Digitalisasi SPK",
          active: true,
        },
      ],
      // variable Page Table
      role_slug: Cookies.get("session_role_slug"),
      loadingTable: true,
      table_data: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      options_status: ["ENABLE", "DISABLE"],
      selected: [],
      status: "ENABLE",
      search: "",
      AddModal: false,
      EditModal: false,
      tanggal_spk: "",
    };
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    getDataTable() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/digitalisasi/spk?tanggal=" +
            self.tanggal_spk +
            "&search=" +
            self.search
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.table_data = response_data.list_data.data;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    hapusData(jb_id) {
      var alert_text = "Apakah Anda yakin?";
      Swal.fire({
        title: "Warning ?",
        text: alert_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          let self = this;
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", jb_id);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "/api/digitalisasi/spk/hapus",
            data: data,
          };
          axios(config)
            .then(function (response) {
              console.log(response);
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman digitalisasi spk segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  self.getDataTable();
                }
              });
            })
            .catch((e) => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
            });
        } else {
          Swal.close();
        }
      });
    },
    //filter data
    filterData() {
      this.getDataTable();
    },

    eksporHandle() {
      let self = this;
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_BACKEND_URL+"/digitalisasi/spk/excel?tanggal=" +
      self.tanggal_spk;
      link.setAttribute('target', '_blank');
      link.click();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <div class="">
                      <div class="row">
                        <div class="col-md-2">
                          <label for="">Tanggal SPK</label>
                          <input type="date" class="form-control" v-model="tanggal_spk"/>
                        </div>
                        <div class="col-md-2">
                          <label for="">&nbsp;</label><br>
                          <button
                            class="btn btn-primary"
                            @click="filterData"
                            type="button"
                            style="border-radius: 0px;"
                          >
                            <i class="fa fa-search"></i> Filter
                          </button>
                        </div>
                      </div>
                    </div>
                    &nbsp;
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-end">
                    <button 
                      @click="eksporHandle" 
                      class="btn btn-success"
                      ><i class="fas fa-file-excel"></i> Ekspor Data</button
                    >
                    &nbsp;
                    <router-link
                      :to="{ name: 'add-spk' }"
                      class="btn btn-info"
                      ><i class="fa fa-plus"></i> Tambah SPK</router-link
                    >
                    &nbsp;
                    <!-- <router-link
                      :to="{ name: 'add-vendor' }"
                      class="btn btn-primary"
                      ><i class="fa fa-plus"></i> Tambah
                      Vendor/Konsultan</router-link
                    > -->
                  </div>
                </div>
                <br />
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      id="searchTable"
                      v-model="search"
                      @input="getDataTable()"
                      placeholder="Cari Data ..."
                    />
                  </div>
                </div>
                <br />
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead>
                      <tr>
                        <th class="text-center text-white" style="width: 50px;background-color: #132d4a">No</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Nomor SPK</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Tanggal SPK</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Nama Perusahaan</th>
                        <th class="text-center text-white" style="width: 175px;background-color: #132d4a">Perihal Pekerjaan</th>
                        <th class="text-center text-white" style="width: 175px;background-color: #132d4a">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="5">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="table_data == ''">
                        <td class="text-center" colspan="9">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(row_data, key_data) in table_data"
                        :key="key_data"
                      >
                        <td class="text-center">
                          {{ key_data + 1 }}
                        </td>
                        <td class="text-center">
                          {{ row_data.nomor_spk }}
                        </td>
                        <td class="text-center">
                          {{ row_data.tanggal_spk }}
                        </td>
                        <td class="text-center">
                          {{ row_data.nama_perusahaan }}
                        </td>
                        <td class="text-center">
                          {{ row_data.jenis_pekerjaan }}
                        </td>
                        <td class="text-center">
                          <div class="btn-group" role="group">
                            <router-link
                              :to="{
                                name: 'edit-spk',
                                params: { id: row_data.id },
                              }"
                              class="btn btn-info btn-sm"
                              ><i class="fa fa-edit"></i> Edit</router-link
                            >
                            <router-link
                              :to="{
                                name: 'detail-spk',
                                params: { id: row_data.id },
                              }"
                              class="btn btn-primary btn-sm"
                              ><i class="fa fa-eye"></i> Detail</router-link
                            >
                            <button v-if="role_slug == 'super_admin'" id="delete" class="btn btn-danger btn-sm" @click="hapusData(row_data.id)">
                              <i class="fa fa-trash"></i> Hapus
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
